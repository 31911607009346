import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/javascript/web-page-rendering",
  "date": "2016-08-04",
  "title": "WEB PAGE RENDERING",
  "author": "admin",
  "tags": ["development", "javascript", "css", "html"],
  "featuredImage": "feature.jpg",
  "excerpt": "Web page rendering is an important subject to study in order to optimize page load times, and to understand how to design solutions to cater for that. For understanding how web pages get rendered, we need to study how browsers render a web page."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`How do browsers handle web page rendering?`}</h2>
    <p>{`Let’s start with an overview of how browser actions for rendering a web page.`}</p>
    <p>{`When the server sends an HTML page to the browser, responding to your request, the following happens on the browser:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`DOM`}</inlineCode>{` – The browser creates DOM from the HTML page`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CSSDOM`}</inlineCode>{` – Styles are loaded and then passed, to create CSSDOM`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Renderer/render object/frame`}</inlineCode>{` – A render tree is created on top of DOM and CSSOM.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Layout`}</inlineCode>{` – For each render tree element, its coordinates are calculated (which is called layout)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Painting`}</inlineCode>{` – Finally all above gets displayed in the browser window (which is called painting)`}</li>
    </ul>
    <p>{`When underlying page structure changes as a result of user interacting with the page, one or many of the above steps will be repeated.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2016-08-04-web-page-rendering/gecko-flow.jpg",
        "alt": "Gecko"
      }}></img></p>
    <p>{`Fig. 1 – Gecko`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2016-08-04-web-page-rendering/webkit-flow.png",
        "alt": "Webkit"
      }}></img></p>
    <p>{`Fig. 2 – Webkit`}</p>
    <Message type="info" title="DOM & CSSOM" content="DOM stands for Document Object Model and CSSOM stands for CSS Object Model." mdxType="Message" />
    <p>{`Render tree reflects the DOM structure except for invisible elements like `}<inlineCode parentName="p">{`<head>`}</inlineCode>{` tag or `}<inlineCode parentName="p">{`display: none`}</inlineCode></p>
    <h2>{`Repaint`}</h2>
    <p>{`Element styles that don’t affect the position on the page, when changed, the browser will repaint the element when new styles are applied.`}</p>
    <p>{`Some of the styles which don’t affect the position of the page are `}<inlineCode parentName="p">{`background-color`}</inlineCode>{` and `}<inlineCode parentName="p">{`visibility`}</inlineCode>{` but not `}<inlineCode parentName="p">{`display`}</inlineCode>{` for example.`}</p>
    <p>{`When a repaint happens, new styles will be applied on top of it.`}</p>
    <h2>{`Reflow/Relayout`}</h2>
    <p>{`When changes result in `}<inlineCode parentName="p">{`document structure`}</inlineCode>{` and `}<inlineCode parentName="p">{`content`}</inlineCode>{` to change, a page reflow happens.`}</p>
    <p>{`Reflow happens as a result of:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`DOM`}</inlineCode>{` – Add, delete or altering elments (DOM manipulation)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Content`}</inlineCode>{` – Content changes eg:- text changes in form fields`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CSS`}</inlineCode>{` – Alter or calculate CSS properties`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Styelsheets`}</inlineCode>{` – Add/remove stylesheets`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Classes`}</inlineCode>{` – Changing of the class attribute`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Browser`}</inlineCode>{` – Changing browser window size including scrolling.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Pseudo-classes`}</inlineCode>{` – Pseudo class activation including :hover`}</li>
    </ul>
    <h2>{`Render Optimization by the Browsers`}</h2>
    <h2>{`Selective Reflow`}</h2>
    <p>{`Browsers only replaint the area where covers changed elements.`}</p>
    <p>{`On the contrary, a statically placed element will make the browser repaint the whole page.`}</p>
    <h2>{`Caching Changes`}</h2>
    <p>{`While running JavaScript code, browsers cache changes, and apply them in a single pass after the code was run.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var body = document.querySelector('body');
 
body.style.backgroundColor = '#FFF'; // repaint
body.style.margin = '20px'; // reflow, repaint
`}</code></pre>
    <p>{`Accessing an element property, triggers a forced reflow. Only 1 reflow and repaint will be hapenning above.`}</p>
    <h2>{`Optimization`}</h2>
    <p>{`The summary of recommendations for optimizing is following:`}</p>
    <h3>{`Valid HTML & CSS`}</h3>
    <p>{`Styles should be included in `}<inlineCode parentName="p">{`<head>`}</inlineCode>{` and scripts to the end of the `}<inlineCode parentName="p">{`<body>`}</inlineCode></p>
    <h3>{`Avoid global or long CSS Selctors`}</h3>
    <p>{`Try and simplify your CSS selctors, and keep nesting levels at minimum.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`div * {...} /* bad */
.list li {...} /* bad */
.list-item {...} /* good */
`}</code></pre>
    <h2>{`Minimize DOM manipulation`}</h2>
    <p>{`Properties and objects can be cached if they were reused. Complicated manipulations should be done in an offline elment(an element stored in memory) and then append it to DOM afterwards.`}</p>
    <h2>{`Animate only absolute/fixed elements`}</h2>
    <p>{`Relatively positioned elements will result in reflow.`}</p>
    <p>{`Disable complicated pseudo class elements while scrolling and page resize.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      